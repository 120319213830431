import React from "react";
import Navbar from "../components/Navbar/Navbar";
import {  css } from "@emotion/core";

// const style = css`
// body {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0 auto;
// }
// `




const NotFound = (props) => {
  const locale = props.pageContext.locale;
  return (
    <>
      <Navbar />
      {locale === "cs-cz" ? (
        <div style={{display: "flex", alignItems: "center", margin: "0 auto", justifyContent: "center", width: "100%", height: "100vh", flexDirection: "column"}}>
          <h1>Stránka nenalezena.</h1>
          <p>Něco se pokazilo. Zkuste to znovu. </p>
        </div>
      ) : (
        <div style={{display: "flex", alignItems: "center", margin: "0 auto", justifyContent: "center", width: "100%", height: "100vh", flexDirection: "column"}}>
          <h1>Page Not Found</h1>
          <p>Oops, Something went wrong. Try to refresh the page. </p>
        </div>
      )}
    </>
  );
};

export default NotFound;
